
import Vue from 'vue';
import SearchResults from './searchResults.vue';
import utils from '../../../../util';
import { Campaign, Order } from './types';
import { RefreshToSeeUpdatedDate } from '../constants';

export default Vue.extend({
  name: 'linearAdminGoogleAdManager',
  components: {
    SearchResults,
  },
  props: ['advertiser', 'editRights'],
  data: (): {
    // local, editable reference to account linking data. Contains only IDs
    // this object is used to POST when making saves/updates
    gamConnectors: Order[];
    loadingAccounts: boolean;
    RefreshToSeeUpdatedDate: string;
  } => ({
    gamConnectors: [],
    loadingAccounts: false,
    RefreshToSeeUpdatedDate,
  }),
  async created(): Promise<void> {
    await this.getGamConnectors();
  },
  computed: {
    loading(): boolean {
      return this.$store.state.linearAdmin?.googleAdManagerLoading;
    },
    noSelectedCampaigns(): boolean {
      if (this.loading || !this.enabled) return false;
      else {
        return !this.gamConnectors?.length;
      }
    },
    error(): string {
      return (
        this.$store.state.linearAdmin?.googleAdManagerError || this.$store.state.linearAdmin?.googleAdManagerSaveError
      );
    },
    enabled(): boolean {
      // temporary solution - for now gam connectors are available only got Sinclair agencies
      return this.advertiser.AgencyPartner === 'Sinclair';
    },
  },
  methods: {
    hasRight(acceptedRoles: Array<string>): boolean {
      return utils.hasRight(this.$store.state.customer.user, acceptedRoles);
    },
    selectCampaign(order: Order, campaign: Campaign) {
      let tmpLinking: Order[] = JSON.parse(JSON.stringify(this.gamConnectors));

      // look for already selected matching order
      const matchingOrder = tmpLinking?.find((o: Order) => o.order_id === order.order_id);

      if (matchingOrder) {
        // if we already have an entry for this order, we can simply add this campaign
        // if campaign has already been added, remove it
        const matchingCampaign = matchingOrder.campaigns.find((c: Campaign) => c.id === campaign.id);

        if (matchingCampaign) {
          matchingOrder.campaigns = matchingOrder.campaigns.filter((c: Campaign) => c.id !== campaign.id);
        } else {
          // add campaign
          matchingOrder.campaigns.push(campaign);
        }
      } else {
        tmpLinking.push({
          order_id: order.order_id,
          order_name: order.order_name,
          campaigns: [campaign],
        });
      }

      // remove any orders with 0 campaigns
      tmpLinking = tmpLinking.filter(o => o.campaigns.length > 0);

      this.$set(this, 'gamConnectors', tmpLinking);
    },
    removeCampaign(order: Order, campaign: Campaign) {
      let tmpLinking = JSON.parse(JSON.stringify(this.gamConnectors));

      tmpLinking = this.gamConnectors.reduce((allOrders: Array<Order>, o: Order) => {
        if (o.order_id === order.order_id) {
          // if this is the matching order, remove the selected campaign
          o.campaigns = o.campaigns.filter(c => c.id !== campaign.id);
        }
        if (o.campaigns.length > 0) allOrders.push(o); // if no campaigns selected in this order, remove the order
        return allOrders;
      }, []);
      this.$set(this, 'gamConnectors', tmpLinking);
    },
    async getGamConnectors(): Promise<void> {
      const data = await this.$store.dispatch('linearAdmin/getGamConnector', {
        id: this.$route.query.ppid,
      });
      this.gamConnectors = data;
    },
  },
});
