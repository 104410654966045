
import Vue from 'vue';
import { Campaign, Order } from './types';

export default Vue.extend({
  name: 'GAMSearchResults',
  data: (): {
    searchLoading: boolean;
    minSearchLength: number;
    search: string | null;
    searchResults: Array<Order> | null;
    searchTimer: Function | null;
    fadeSave: boolean;
    savedResults: object;
  } => ({
    minSearchLength: 4,
    searchLoading: false,
    searchResults: null,
    search: null,
    searchTimer: null,
    fadeSave: true,
    savedResults: {},
  }),
  props: ['selectedOrders'],
  created() {
    Vue.nextTick(() => {
      this.fadeSave = true;
    });
  },
  computed: {
    saveButtonStyle(): object | null {
      if (this.fadeSave) return { opacity: 0.5 };
      return null;
    },
    searchHint(): string {
      const searchLength = this.search?.length;
      if (searchLength < this.minSearchLength) {
        if (searchLength === 0) return `Type ${this.minSearchLength} or more characters`;
        else return `Type ${this.minSearchLength - searchLength} more characters`;
      }
      return '';
    },
  },
  methods: {
    async save(): Promise<void> {
      // returns array of campaign ids
      function getCampaigns(all: Array<string>, campaign: Campaign) {
        all.push(campaign.id);
        return all;
      }

      if (!this.fadeSave) {
        try {
          const orders = this.selectedOrders.reduce((all: Array<string>, o: Order) => {
            const campaigns = o.campaigns.reduce(getCampaigns, []);
            all.push(...campaigns);
            return all;
          }, []);

          await this.$store.dispatch('linearAdmin/addGamConnector', {
            id: this.$route.query.ppid,
            campaign_ids: orders,
          });

          this.fadeSave = true;
          this.$emit('reloadOrders'); // sync up data after save
        } catch (err) {}
      }
    },
    accountIsSelected(order: Order, campId: string): boolean {
      if (this.selectedOrders && this.selectedOrders.length) {
        const matchedOrder: Order = this.selectedOrders.find((o: Order) => o.order_id === order.order_id);
        if (!matchedOrder) return false; // no matching order so we can stop searching

        const matchingCampaign = matchedOrder.campaigns.find((c: Campaign) => c.id === campId);

        if (matchingCampaign) return true;
      }
      return false;
    },
    selectCampaign(order: Order, campaign: Campaign): void {
      this.$emit('selectCampaign', order, campaign);
    },
    async gamSearch(search: string): Promise<void> {
      const results = await this.$store.dispatch('linearAdmin/getGAM', {
        search: search,
      });

      this.searchResults = results?.data;
      // store results if items found.
      if (this.searchResults?.length > 0) {
        // store results based on search term
        this.savedResults[search] = JSON.stringify(this.searchResults);
      }
      this.searchLoading = false;
    },
    onSearch(search: string): void {
      // clear focus timer
      clearTimeout(this.searchTimer);
      if (search.length < this.minSearchLength) {
        this.searchResults = null; // reset results
        return;
      }
      // set timeout to allow for user input.
      this.searchTimer = setTimeout(() => {
        this.searchLoading = true;
        this.searchResults = null; // reset results
        // check for saved results.
        if (this.savedResults.hasOwnProperty(search)) {
          try {
            // try to get the results
            this.searchResults = JSON.parse(this.savedResults[search]);
            this.searchLoading = false;
          } catch (err) {
            // on error clear that store item and search again.
            delete this.savedResults[search];
            this.gamSearch(search);
          }
        } else {
          delete this.savedResults[search];
          // if we can't find the cached result, search for them.
          this.gamSearch(search);
        }
      }, 500);
    },
  },
  watch: {
    selectedOrders: {
      handler(orders: Array<object>, old: Array<object>) {
        if (typeof old === 'undefined') {
          // happens on initialization
          this.fadeSave = true;
          return;
        }
        if (typeof orders === 'undefined') {
          // orders will be undefined from the server is no orders have been assigned
          if (typeof old === 'undefined' || old.length < 1) {
            this.fadeSave = true;
            return;
          }
        }

        const ordersString = JSON.stringify(orders);
        const oldOrdersString = JSON.stringify(old);

        if (ordersString === oldOrdersString) {
          this.fadeSave = true;
        } else {
          this.fadeSave = false;
        }
      },
    },
  },
});
